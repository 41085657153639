/* eslint-disable @nx/enforce-module-boundaries */
// 'use client';
import { HomePage } from 'v4/snippets/home';
import { AuthenticatedProviders } from 'v4/snippets/providers/authenticated';

export const Index = () => {
  return (
    <AuthenticatedProviders>
      <HomePage />
    </AuthenticatedProviders>
  );
};

export default Index;
